import request from '@/utils/request'

export function listWisdom (data) {
  return request({
    url: '/wisdomLib/list',
    method: 'POST',
    data
  })
}
export function addWisdom (data) {
  return request({
    url: '/wisdomLib/add',
    method: 'POST',
    data
  })
}
export function removeWisdom (data) {
  return request({
    url: '/wisdomLib/remove',
    method: 'POST',
    data
  })
}
export function updateWisdom (data) {
  return request({
    url: '/wisdomLib/update',
    method: 'POST',
    data
  })
}
export function preFile (url) {
  return request({
    url: url,
    method: 'GET'
  })
}
