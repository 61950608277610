<template>
  <div :style="{ overflow: 'hidden' }">
    <div style="width: 100vw; height: 550px; background-image: url('/wisdomLibBanner.png'); margin-left: -20px; margin-top: -20px; background-size:cover; background-position: center">
      <div style="color: #FFF; font-size: 60px; text-align: center; padding-top: 100px;">
        <el-input @change="fetchList" suffix-icon="el-icon-search" style="width: 30%" v-model="keyword" placeholder="请输入报告关键字 回车搜索"></el-input>
      </div>
    </div>
    <div v-loading="loading" style="width: 90%; background: rgba(255,255,255,1); border-radius: 10px; margin: 0 auto; margin-top: -300px; min-height: 300px;  padding: 20px; overflow: auto">
      <el-tabs v-model="selTabName" v-if="typeArray.length > 0" tab-position="top" type="card">
        <el-tab-pane :label="item" :name="item" v-for="(item, index) in typeArray" :key="index">
          <div v-for="item2 in wisdomArray[index]" @click="showInfo(item2.url)" style="width: 29%; background: #FFF; height: 450px; border-radius: 10px; box-shadow: lightgrey 0px 0px 10px 0px; margin: 10px 2%; float: left; cursor: pointer">
            <div :style="{backgroundImage: 'url(' + item2.cover + ')'}" style="width: 100%; height: 250px; background-size:cover; background-position: center; border-radius: 10px 10px 0 0"></div>
            <div>
              <div style="font-size: 23px; padding: 20px 20px 0 20px; margin-bottom: 20px; overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1;">
                {{ item2.title }}</div>
              <div style="font-size: 15px; padding: 0px 20px 0px 20px; height: 35px;overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;">{{ item2.summary }}</div>
            </div>
            <div style="width: 150px; height: 40px; background: #3372C7; margin: 0 auto; margin-top: 20px; color: #FFFFFF; text-align: center; line-height: 40px; border-radius: 5px">
              立即预览
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div style="text-align: center; line-height: 300px" v-else>
        暂无相关报告
      </div>
    </div>
    <el-dialog
      style="padding: 0; overflow-y: hidden"
      title="加载中，请稍等..."
      :visible.sync="dialogVisible"
      :fullscreen="true"
      :destroy-on-close="true"
      :modal="false"
      @closed="closeDialog"
    >
      <iframe :src="url" frameborder="0" width="100%" height="600px" style="height: calc(100vh - 63px)"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import { listWisdom } from '@/api/wisdomLib'
import { getToken, setToken } from '@/utils/auth'

export default {
  data () {
    return {
      loading: true,
      keyword: '',
      typeArray: [],
      wisdomArray: [],
      dialogVisible: false,
      url: '',
      selTabName: ''
    }
  },
  mounted () {
    if (this.$route.meta.type && this.$route.meta.type === 'share') {
      this.fetchListAndOpenSomeone()
      setToken(this.$route.params.token)
      localStorage.setItem('customType', '{"type":"1","token":"' + this.$route.params.token + '","expire":259200}')
    } else {
      this.fetchList()
    }
  },
  methods: {
    scrollToTop () {
      document.documentElement.scrollTop = 0
    },
    setOverflowHidden () {
      // 设置body的overflow为hidden
      document.body.style.overflow = 'hidden'
    },
    removeOverflow () {
      // 删除body的overflow样式
      document.body.style.overflow = ''
    },
    showInfo (url) {
      this.url = url + '&token=' + getToken()
      this.setOverflowHidden()
      this.scrollToTop()
      this.dialogVisible = true
    },
    async fetchList () {
      this.loading = true
      const res = await listWisdom({ keyword: this.keyword })
      var _this = this
      _this.typeArray = []
      _this.wisdomArray = []
      Object.keys(res.data).forEach(function (key) {
        _this.typeArray.push(key)
        _this.wisdomArray.push(res.data[key])
      })
      if (_this.typeArray.length > 0) {
        _this.selTabName = _this.typeArray[0]
      }
      this.loading = false
    },
    async fetchListAndOpenSomeone () {
      this.loading = true
      const res = await listWisdom({ keyword: this.keyword })
      var _this = this
      _this.typeArray = []
      _this.wisdomArray = []
      Object.keys(res.data).forEach(function (key) {
        _this.typeArray.push(key)
        _this.wisdomArray.push(res.data[key])
      })
      if (_this.typeArray.length > 0) {
        _this.selTabName = _this.typeArray[0]
      }
      this.loading = false
      console.log(this.wisdomArray)
      for (var i = 0; i < this.wisdomArray.length; i++) {
        for (var j = 0; j < this.wisdomArray[i].length; j++) {
          if (this.wisdomArray[i][j].id === this.$route.params.id) {
            if (this.$route.params.shareid) {
              this.showInfo(this.wisdomArray[i][j].url.split('/preview')[0] + '/preview_share?id=' + this.$route.params.shareid)
            } else {
              this.showInfo(this.wisdomArray[i][j].url)
            }
          }
        }
      }
    },
    closeDialog () {
      this.removeOverflow()
      this.url = ''
      if (this.$route.meta.type && this.$route.meta.type === 'share') {
        this.$router.push('/wisdomLib')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog.is-fullscreen .el-dialog__header {
  padding: 0px !important;
  //padding-bottom: 10px;
  //display: none !important;
  height: 0px;
}
/deep/ .el-dialog.is-fullscreen .el-dialog__body {
  padding: 0px !important;
}
/deep/ .el-dialog.is-fullscreen .el-dialog__headerbtn {
  top: 15px !important;
}
/deep/ .el-dialog.is-fullscreen .el-icon-close{
  color: #FFFFFF !important;
  font-weight: 900;
  font-size: 25px;
  background: #4471C1;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  line-height: 50px;
  position: fixed;
  left: 20px;
}
/deep/ .el-dialog.is-fullscreen {
  margin-top: 63px !important;
}
</style>
